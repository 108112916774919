@use 'sass:color';

.bg-light {
  background-color: hsl(0, 0%, 97%);
}
$rounded-border-radius: 12px;

.card {
  .featured-card {
    border-radius: $rounded-border-radius;
  }
}

.table-head-row {
  border-top-left-radius: $rounded-border-radius;
  border-top-right-radius: $rounded-border-radius;

  .table-cell {
    // padding: 1rem 1rem;
    font-size: 0.9rem;
    // border-right: 1px solid get-color(lighter-blue);

    &:last-child {
      border-right: none;
    }
  }
}

.table-body-row,
.table-head-row {
  padding: 0.75rem 0.75rem;
}

.table-body-row:nth-child(odd) {
  background-color: hsl(0, 0%, 97%);
}

.table-body-row:nth-child(even) {
  background-color: hsl(0, 0%, 100%);
}

.table-body-row:last-of-type {
  border-bottom-left-radius: $rounded-border-radius;
  border-bottom-right-radius: $rounded-border-radius;
}

.custom-table {
  margin-bottom: 80px;
  border-bottom-left-radius: $rounded-border-radius;
  border-bottom-right-radius: $rounded-border-radius;
}

// .table-body-row:hover {
//   background-color: darken(color.adjust(hsl(0, 0%, 97%), $lightness: 3% * -1), 3%);
// }
