.heart-icon {
  animation: popIn 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}

@keyframes popIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
