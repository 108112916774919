@media print {
  .col-lg-4 {
    width: 50%; /* Adjust to the width you desire */
    float: left;
    box-sizing: border-box;
  }

  /* Reset some Bootstrap styles that might affect printing */
  .col-md-6,
  .col-lg-4 {
    max-width: 100%;
    flex: 0 0 auto;
  }

  .roster-influencer-holder {
    min-height: 75vh;
  }
}
