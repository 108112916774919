@import './variables';
@import 'bootstrap/scss/bootstrap';
@import './autosuggest';
@import './Login';
@import './print-styles';
@import './buttons';

#root {
  min-height: 100vh;
}

body {
  background: white;
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-container {
  a {
    color: get-color(bright-blue);
    // &:hover {
    //   color: $your-custom-hover-color; // Customize the hover color
    // }
  }
}

.page-title {
  color: get-color(eui-blue-gray);
  font-size: 34px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@each $color, $value in $colors {
  .bg-#{'' + $color} {
    background-color: $value !important;
  }

  .custom-bg-#{'' + $color}-gradient {
    background: linear-gradient(
      0deg,
      scale-color($value, $lightness: 60%) 0%,
      change-color($value, $lightness: 80%) 10%,
      change-color($value, $lightness: 95%) 20%,
      change-color($value, $lightness: 99%) 100%
    );
  }

  .custom-bg-#{'' + $color}-light {
    background-color: scale-color($value, $lightness: 92%);
  }

  // .custom-bg-#{$color}-dark {
  //   background-color: scale-color($value, $lightness: 8%) !important;
  // }

  .text-#{'' + $color} {
    color: $value !important;
  }
}

.euiBasicTable,
.custom-basic-table {
  .euiTableHeaderButton-isSorted .euiTableSortIcon {
    fill: #cdd0dd !important;

    &:hover {
      fill: #cdd0dd !important;
    }
  }

  thead {
    tr,
    tr.table-custom-dark {
      background-color: #3f4254;

      th {
        font-size: 0.9em;
        background-color: #3f4254;
      }
    }

    $table-border-radius: 8px;

    .euiTableHeaderCell,
    .header-cell {
      color: #cdd0dd !important;
      padding: 8px 0px;

      &:hover {
        color: #cdd0dd !important;
      }

      // First header cell rounded corners
      &:first-child {
        border-top-left-radius: $table-border-radius;
        border-bottom-left-radius: $table-border-radius;
      }

      // Last header cell rounded corners
      &:last-child {
        border-top-right-radius: $table-border-radius;
        border-bottom-right-radius: $table-border-radius;
      }
    }
  }

  .euiTableCellContent__text {
    color: #cdd0dd !important;

    &:hover {
      color: #cdd0dd !important;
    }
  }

  tbody {
    tr:first-child {
      td {
        border-top: none;
      }
    }
  }
}

.text-eui-gray {
  color: #656565 !important;
}

.geo-items {
  border-bottom: 1px solid #e5e5e5;
  // padding: 10px 0;
}

.btn-primary {
  color: #fff;
  // font-weight: bold;
}

.text-orange {
  color: get-color(pastel-orange);
}

.text-gray {
  color: get-color(light-gray);
}

$custom-fonts: (
  'neuton': "'Neuton', serif",
  'barlow': "'Barlow Condensed', sans-serif",
  'lora': "'Lora', serif",
);

@each $font-name, $font-value in $custom-fonts {
  .font-#{$font-name} {
    font-family: #{$font-value} !important;
  }
}

.font-open-sans {
  font-family: 'Open Sans', sans-serif !important;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.sheet-header-text {
  font-family: 'Neuton', serif;
  font-size: 3em;

  &.font-roboto {
    text-transform: uppercase;
  }

  &.font-barlow {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.roster-influencer-holder {
  font-family: 'Neuton', serif;
  font-size: 1.25em;
  letter-spacing: 2px;
  font-weight: 500;

  &.circle-portraits {
    img {
      max-width: 100%;
      max-height: 260px;
      min-height: 260px;
      min-width: 260px;
      width: 260px;
      height: 260px;
    }
  }

  .image-holder {
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }

  .influencer-name,
  .roster-name {
    color: get-color(pastel-orange);
    font-size: 1.6em;
    line-height: 1em;
    font-weight: 600;

    &.color-black {
      color: black;
    }

    &.font-barlow {
      font-family: 'Barlow Condensed', sans-serif;
      // font-size: 1.6em;
      font-weight: 500;
      letter-spacing: 3px;
      text-transform: uppercase;
    }

    &.font-open-sans {
      font-weight: 500;
    }
  }

  .title-text {
    font-family: 'Noto Sans', sans-serif;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 1px;
  }

  .roster-social-icon {
    padding: 1px 6px 4px 6px;
    min-width: 35px;
  }

  .follower-display {
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.6em;
    padding: 0.5rem;
  }
}

.roster-social-display {
  padding-left: 6px;
  border-left: 3px solid;

  &:nth-child(4n + 1) {
    border-left-color: #e2a87b; // First color for the 1st, 5th, 9th, etc.
  }

  &:nth-child(4n + 2) {
    border-left-color: #f1205c; // Second color for the 2nd, 6th, 10th, etc.
  }

  &:nth-child(4n + 3) {
    border-left-color: #6461ff; // Third color for the 3rd, 7th, 11th, etc.
  }

  &:nth-child(4n) {
    border-left-color: #7723bc; // Fourth color for the 4th, 8th, 12th, etc.
  }
}

.profile-pic-holder {
  width: 100px;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.portrait-rounded {
  img {
    border-radius: 50%;
    vertical-align: middle;
    max-width: 75%;
  }

  @include media-breakpoint-up(md) {
    img {
      max-width: 100%;
    }
  }
}

.grid-drag-item {
  width: 165px;
  height: 165px;
  margin: 8px;
  background-color: get-color(sandy);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: bold;

  img {
    width: 45%;
    height: 45%;
  }
}

.talent-sheet-list.campaign {
  .portrait-rounded {
    max-width: 140px;
  }
}

.talent-sheet-roster {
  min-height: 120px;
  background: get-color(deep-space-sparkle);
  .portrait-rounded {
    img {
      border-radius: 50%;
      max-height: 70px;
    }
  }
}

.talent-sheet-table {
  .portrait-rounded {
    img {
      border-radius: 50%;
      max-height: 70px;
    }
  }
}

.drag-item {
  user-select: none;
  background: get-color(timberwolf);
}

.selectable {
  cursor: pointer;
}

// .tag {
//   font-size: 0.9em;
//   margin: 4px;
// }

.bg-sandy {
  background-color: get-color(sandy);
}

.bg-sandy-lighter {
  background-color: get-color(sandy-lighter);
}

.bottom-drop-shadow {
  position: relative;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.talent-profile {
  background-color: get-color(sandy);
  min-height: 40vh;
}

.influencers-table {
  img {
    max-width: 140px;
  }

  .tag {
    font-size: 0.8em;
  }
}

.social-accounts-table {
  img {
    max-width: 100px;
  }

  .tag {
    font-size: 0.8em;
  }
}

.text-bold {
  font-weight: 600;
}

.instagram-post-holder {
  $ig-height: 360px;
  position: relative;
  height: $ig-height;

  .image-holder {
    position: absolute;
    display: block;
    width: 100%;
    height: auto;
    height: $ig-height;
    background-position: center center;
    background-size: cover;
    border-radius: 0.5em;
    img {
      width: 100%;
      height: auto;
    }
  }
  .text-holder {
    position: absolute;
    padding: 12px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: $ig-height;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgb(255, 255, 255, 0.8);
    overflow-y: hidden;

    &.show-holder {
      opacity: 0.8;
      background-color: #000;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  &:hover {
    .text-holder {
      opacity: 1;
    }
  }
}

.audience-stats-container {
  .col-4 {
    margin-bottom: 1.75em;
  }
}

.gender-chart-container {
  width: 75%;
}

.profile-social-icon {
  font-size: 2.5em;
}

.team-influencer-note {
  min-height: 180px;
  border-radius: 4px;
  padding-top: 8px;
}

.error {
  color: get-color(caput-mortuum);
}

.success {
  color: get-color(success);
}

.message-box {
  min-height: 1.5em;

  .error {
    color: get-color(caput-mortuum);
  }
}

.public-team-roster-influencer-holder {
  .roster-name {
    font-family: 'Neuton', serif;
    font-size: 1.8em;
    line-height: 1em;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.public-team-roster {
  h2 {
    font-family: 'Neuton', serif;
    font-size: 2.5em;
  }
}

.navbar {
  border-bottom: 1px solid get-color(xanadu);

  &.maryland {
    background-color: get-color(maryland-red-dark);
    color: white;

    .nav-link {
      color: white;
    }
  }
}

.social-follower-counts {
  font-size: 0.9em;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    font-size: 1em;
  }
}

.roster-influencer-holder {
  div.d-flex {
    margin-bottom: 10px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 4px;
    }
  }
}

.pagebreak {
  page-break-after: always;
  margin-bottom: 170px;
}

.team-name-box {
  font-size: 1.3em;
}

.navbar-light {
  .active {
    text-decoration: underline;
    text-underline-offset: 0.1em;
  }
}

.navbar-light .navbar-nav .nav-link {
  font-size: 1.1em;
  color: black;
}

.team-roster-social-list {
  list-style-type: none;
  a {
    text-decoration: none;
  }
}

a.no-decoration {
  text-decoration: none;
  color: inherit;
}

.bg-light-contrast {
  background-color: rgb(210, 210, 210);
}

.hover-darken {
  filter: brightness(1);

  &:hover {
    filter: brightness(0.9);
    transition: all 0.8s ease;
  }
}

.popup {
  z-index: 1070;
}

.popup-menu {
  &.tags-popup {
    font-size: 0.8em;
    min-width: 25vw;
    max-width: 25vw;
  }
}

.table {
  &.table-dark {
    &.maryland-theme {
      tr {
        background-color: var(--maryland-red);
      }
    }
  }
}

.social-accounts-table {
  font-size: 0.85em;
}

@media print {
  .do-not-print {
    display: none;
  }

  .print-col-6 {
    width: 50%;
  }

  .print-col-4 {
    width: 33.333333%;
  }
}

.collection-sheet-preview {
  .portrait-rounded img {
    height: 80px;
    width: 80px;
  }
}

.team-logo-container {
  min-width: 30%;
  text-align: center;
}

.talent-description-text {
  p:not(:last-child) {
    margin-bottom: 14px;
  }
}

.profile-v2 {
  $social-pushup: 70px;

  font-family: 'Inter', sans-serif;
  min-height: 90vh;
  padding-top: $social-pushup;
  padding-bottom: 2rem;

  .profile-team-logo-container {
    min-width: 30%;
    text-align: center;
  }

  img.profile-team-logo {
    max-height: 200px;
    max-width: 90%;
  }

  .social-icon-column {
    border-left: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }

  .selected-social-icon-column {
    border-left: 3px solid var(--bs-body-color);
    transform: translateX(3px);
  }

  hr {
    margin: 1.5em;
    border-top: 2px solid black;
    opacity: 0.5;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .talent-name {
    font-size: 2.5em;
    line-height: 1em;
  }

  $description-padding: 10%;

  .talent-description-text {
    padding-left: $description-padding;
    padding-right: $description-padding;

    p:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  .horizontal-flanked-text {
    overflow: hidden;
    text-align: center;
  }

  .post-category-select {
    transition: color 0.2s ease;
  }

  .post-category-select:hover {
    color: get-color(lighter-purple);
  }

  .horizontal-flanked-text:before,
  .horizontal-flanked-text:after {
    background-color: #000;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  .horizontal-flanked-text:before {
    right: 0.5em;
    margin-left: -50%;
  }

  .horizontal-flanked-text:after {
    left: 0.5em;
    margin-right: -50%;
  }

  .line {
    display: inline-block;
    width: 100%;
    border-top: 0.2px solid black;
  }

  .headline {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
  }

  .text-bold {
    font-weight: 500;
  }
  --rounded-box-border-radius: 2.4rem;

  .very-round-box {
    border-radius: var(--rounded-box-border-radius);
    height: 100%;
  }

  .social-block-column {
    @include media-breakpoint-up(md) {
      margin-top: -$social-pushup;
    }
  }

  .social-box {
    border-radius: 2.5rem;

    .card-header {
      border-top-left-radius: var(--rounded-box-border-radius);
      border-top-right-radius: var(--rounded-box-border-radius);
    }

    .social-box-content {
      border-bottom-left-radius: var(--rounded-box-border-radius);
      border-bottom-right-radius: var(--rounded-box-border-radius);
    }

    .headline {
      font-size: 2.5em;
    }
    @include media-breakpoint-up(md) {
      .headline {
        font-size: 2.5em;
      }
    }

    .follower-count {
      font-size: 2.2em;
      letter-spacing: -0.04em;

      @include media-breakpoint-up(md) {
        font-size: 2.2em;
      }
    }

    .social-icon {
      padding: 10px;
      border-radius: 46%;
      color: white;
      font-size: 4em;

      @include media-breakpoint-up(md) {
        font-size: 4em;
      }

      &:hover {
        transform: scale(1.15);
      }

      transition: all 0.2s ease;
    }
  }

  .stats-box {
    .headline {
      font-size: 2.1em;
    }

    .rank-digit {
      padding: 0;
      width: 1rem;
    }

    .country-rank,
    .fancy-rank {
      font-family: 'Barlow Condensed', sans-serif;
      font-weight: 500;
      font-size: 2em;
      // letter-spacing: -0.04em;
    }

    .country-name,
    .list-name {
      font-weight: 500;
      padding-top: 0.5em;
      font-size: 1.2em;
    }

    .interests {
      font-size: 1.1em;
    }
  }

  .posts-box {
    .headline {
      text-align: center;
      font-size: 1.4em;
    }

    @media screen and (min-width: 768px) {
      .headline {
        font-size: 2.4em;
      }
    }

    .category-headline {
      transition: all 0.2s ease;
    }

    .category-selected {
      font-weight: 600;
    }

    .category-headline {
      &:hover {
        color: get-color(gray-600);
        // transform: scale(1.05);
      }
    }

    .post-card {
      height: 360px;
      position: relative;

      .image-holder {
        position: absolute;
        display: block;
        width: 100%;
        height: auto;
        height: 360px;
        background-position: center center;
        background-size: cover;
      }

      img.squarify {
        width: 100%; /* Set the width of the image to 100% */
        height: 100%; /* Set the height of the image to 100% */
        object-fit: cover; /* This will stretch the image to fit the container, while maintaining its aspect ratio */
        object-position: center; /* This will center the image within the container */
      }
    }
  }
}

.influencer-post-editor {
  .selected {
    font-size: 1.3em;
  }
}

.team-logo-box {
  img {
    max-width: 80%;
    max-height: 140px;
  }
}

.collection-photo-holder {
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
}

.zoom-hover {
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.03);
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #282828;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.square-image-container {
  position: relative;
  width: 100%; /* The size you want */
}
.square-image-container:after {
  content: '';
  display: block;
  padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.square-image-container img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.copy-icon:focus {
  outline: none;
}

.roster-item-container {
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
}

.reflected-blur-image-container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.reflected-blur-image {
  position: relative;
  left: 0;
  height: 100%;
  background-size: 100% 100%;
  // background-size: cover;
  // background-position: center bottom;
  transform: scaleY(-1);
  margin-top: 50%;
  bottom: -30%;
  filter: blur(12px);
  z-index: -1;
}

.roster-profile-container {
  height: 590px;

  @include media-breakpoint-up(lg) {
    height: 550px;
  }

  @include media-breakpoint-up(xxl) {
    height: 600px;
  }

  // overflow: hidden;
  width: 100%;
  // background-color: rgba(255, 255, 255, .7);

  .image-reflection-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .reflection-image {
    width: 100%;
    height: 100%;
    display: block;
  }

  $overlay-height-xs: 45%;
  $overlay-height-md: 55%;
  $overlay-height-lg: 45%;
  $overlay-height-xxl: 37%;

  .reflection {
    position: absolute;

    bottom: -$overlay-height-xs;
    height: $overlay-height-xs;

    @include media-breakpoint-up(sm) {
      bottom: -$overlay-height-md;
      height: $overlay-height-md;
    }

    @include media-breakpoint-up(xl) {
      bottom: -$overlay-height-lg;
      height: $overlay-height-lg;
    }

    @include media-breakpoint-up(xxl) {
      bottom: -$overlay-height-xxl;
      height: $overlay-height-xxl;
    }

    left: 0;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    transform: scaleY(-1);
    overflow: hidden;
  }

  .roster-profile-overlay {
    background-color: rgba(255, 255, 255, 0.7);
    height: 100.5%;

    @include media-breakpoint-up(xxl) {
      height: 100%;
    }
  }

  $overlay-blur: 10px;

  .overlay {
    position: absolute;

    bottom: -$overlay-height-xs;
    height: $overlay-height-xs;

    @include media-breakpoint-up(sm) {
      bottom: -$overlay-height-md;
      height: $overlay-height-md;
    }

    @include media-breakpoint-up(xl) {
      bottom: -$overlay-height-lg;
      height: $overlay-height-lg;
    }

    @include media-breakpoint-up(xxl) {
      bottom: -$overlay-height-xxl;
      height: $overlay-height-xxl;
    }

    left: 0;
    width: 100%;
    backdrop-filter: blur($overlay-blur);
    -webkit-backdrop-filter: blur($overlay-blur);
    /* transform: scaleY(-1); */
  }

  // .overlay::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  .image-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 1rem;
    text-align: center;
    z-index: 1;
  }
}
@include table-variant(special, get-color(dark-purple));

$very-dark-background: #2b2b2b;
$dark-background: #343541;
$slightly-dark-background: #d3d3d3;
$dark-mode-color: #f5f5f5;

$dark-colors: (
  'darkest': #000000,
  'darker': #1a1a1a,
  'dark': #333333,
  'less-dark': #4d4d4d,
  'light-dark': #666666,
  'lighter-dark': #808080,
  'lightest-dark': #d3d3d3,
);

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);

@each $color-name, $color in $dark-colors {
  .bg-#{'' + $color-name} {
    background-color: $color;
  }
}

@each $color-name, $color in $grays {
  .bg-gray-#{'' + $color-name} {
    background-color: $color;
  }
}

.dark-mode {
  background: map-get($grays, '900');
  color: $dark-mode-color;

  .very-round-box {
    background-color: $gray-200;
    color: black;
  }

  a {
    color: $dark-mode-color;
    text-decoration: underline;
  }

  hr {
    border-color: #f0f0f0;
  }
}

.rather-dark-background {
  background: $dark-background;
  color: $dark-mode-color;
}

.dark-mode-image {
  filter: invert(1) brightness(2);
}

.bg-white {
  color: black;
}

.icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.icon-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f0f0f0;
  transform: scale(0);
  transition: transform 0.1s ease;
}

.icon-wrapper:hover::before {
  transform: scale(1);
}

.arrow-icon {
  z-index: 1;
}

blockquote {
  border-left: 2px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px;
}

.msg {
  p {
    margin-bottom: 1rem;
  }

  blockquote {
    margin-bottom: 1rem;
    border-left: 2px solid #ccc;
    background-color: #f9f9f9;
    padding: 10px;
  }
}

// .site-container {
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;

//   .site-bottom-section {
//     flex-grow: 1; /* this allows the bottom section to take up remaining space */
//   }
// }

.public-roster-container {
  padding-top: 20px;
  min-height: 80vh;
}

// ROSTER

.black-and-white {
  filter: grayscale(100%);
  transition: filter 0.6s ease; // 0.5s is the duration of the animation, you can change it as needed

  &:hover {
    filter: grayscale(0%);
  }
}

.search-button {
  display: block;
  width: 100%; /* Full width */
  height: auto; /* Adjust this to your needs */
  padding: 14px;
  font-size: 1.5em;
  border: none; /* No border */
  border-radius: 20px; /* Round corners */
  color: white; /* Text color */
  background-color: #007bff; /* Background color */
  text-align: center; /* Center the text */
  cursor: pointer; /* Hand cursor on hover */

  &:hover {
    background-color: #0056b3;
  }
}

.search-area {
  font-size: 0.9em;
}

.highlight-chooser {
  .option {
    padding-left: 8px;
    border-left: 4px solid transparent;
    cursor: pointer;
    transition: border-left-color 0.2s ease, color 0.2s ease; // Add this line

    &.highlighted {
      border-left: 4px solid $primary;
      color: $primary;
    }
  }
}

.custom-tag {
  position: relative;
  padding-right: 30px; /* adjust based on your design */
}

.custom-tag::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 10px solid transparent; /* adjust size accordingly */
  border-bottom: 10px solid transparent; /* adjust size accordingly */
  border-left: 20px solid #e6fcfc; /* match badge color */
  width: 0;
  height: 0;
}

.action-button {
  color: gray;
}

.action-button:hover {
  color: red;
}

.bg-subdued {
  background-color: #f7f8fc;
}

.text-link-blue {
  color: get-color(eui-bright-blue);
}

#root {
  background-color: #f7f8fc;
}

.btn-bright-blue {
  background-color: #26aefb;
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: gray;
    color: white;
  }
}

.bg-bright-blue {
  background-color: #26aefb !important;
  // color: white;
}

.btn-bright-red {
  background-color: #ff494e;
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: gray;
    color: white;
  }
}

.custom-navbar-link {
  &.active {
    border-bottom: 4px solid get-color(bright-blue);
  }
}

.no-link-color {
  color: inherit;
  text-decoration: none; /* Optional: Remove underline */
}

.creator-call-description {
  p {
    margin-bottom: 1rem;
  }
}
