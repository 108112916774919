#grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.grid-item {
  background-color: transparent;
  padding: 8px;
  text-align: center;
  cursor: grab;
  transition: background-color 0.2s ease;

  &.border {
    border: 1px solid #ccc;
  }
}

.drag-over {
  background-color: lightblue;
}
