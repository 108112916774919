.stats-box {
  border-radius: 7px;
  // display: flex;
  // align-items: center;
}

.stats-indicator {
  width: 5px;
  height: 60px;
  border-radius: 2px;
  margin-right: 10px;
}

.stats-info h1 {
  margin: 0;
  color: #333;
  // font-size: 20px;
}

.stats-percentage {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.stats-change {
  margin-left: auto; /* push to the right */
  background-color: #ffd6e7;
  border-radius: 10px;
  padding: 5px 10px;
  color: #fc6471;
  font-weight: bold;
}

.stats-change span {
  display: inline-flex;
  align-items: center;
}
