.switch-container {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 5px;
  width: 300px;
  justify-content: space-between;
}

.switch-button {
  flex: 1;
  background: none;
  border: none;
  border-radius: 25px;
  padding: 10px 30px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.switch-button.active {
  background-color: #374151;
  color: white;
}

.switch-button:not(.active) {
  color: #9ca3af;
}

.switch-button:not(.active):hover {
  color: #6b7280;
}
