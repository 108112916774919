.tag {
  display: inline-flex;
  align-items: center;
  margin: 5px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  /* border: 2px solid;  */

  &.outline {
    background-color: white; /* Set tag body to white for outline variant */

    &.tag-name {
      background-color: transparent;
      border: 1px solid; /* Add border to tag name for outline variant */
    }
  }
}

.tag-name {
  display: flex;
  align-items: center;
  padding: 2px 6px;
  /* border-radius: 15px 0 0 15px; Rounded left side */
}

.tag-name .remove {
  margin-left: 8px;
  cursor: pointer;
}

.tag .remove:hover {
  color: red; /* Change color on hover */
}

.tag-triangle {
  width: 0;
  height: 0;
  border-top: 15px solid transparent; /* Adjust size as needed */
  border-bottom: 15px solid transparent; /* Adjust size as needed */
  border-left: 10px solid; /* Adjust size as needed, color comes from inline style */
  border-radius: 0 5px 5px 0; /* Rounded right side */
}

.tag.outline .tag-triangle {
  border-left-color: inherit; /* Ensure triangle border matches the tag border for outline variant */
}
