@import '../styles/_variables.scss';

.switch-selector-container {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 36px;
  padding: 3px;

  .switch-option {
    padding: 12px 12px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 24px;
    transition: background-color 0.3s;
    font-size: 14px;
  }

  .switch-option:hover {
    background-color: map-get($colors, gray-500);
    color: white;
  }

  .switch-option.selected {
    background-color: map-get($colors, gray-800);
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
