.table-container {
  width: 100%;
  transition: all 0.5s ease;

  &.open {
    width: 75%;
  }
}

.details-container {
  position: fixed;
  right: 0;
  top: 60px;
  width: 425px;
  max-height: calc(100vh - 60px);
  overflow: auto;
  visibility: hidden;
  transition: opacity 0.4s;
  opacity: 0;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}
